import React from 'react';
import { Link } from 'react-router-dom';

export default function Button(props) {
  const { to, children } = props;
  return (
    <Link className="button" to={to} exact="true">
      {children}
    </Link>
  );
}
