import React from 'react';

export default function Nero(props) {
  const { title, caption } = props;
  return (
    <div className="nero">
      <div className="nero__title">{title}</div>
      {caption ? <div className="nero__caption">{caption}</div> : null}
    </div>
  );
}
