import React, { Fragment, useEffect, useState } from 'react';
import { useVideo, useWindowScroll, useEffectOnce } from 'react-use';

import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import Section from '../components/Section';
import Footer from '../components/Footer';
import Video from '../components/Video';
import Sep from '../components/FullSep';
import Button from '../components/Button';
import Chapter from '../components/Chapter';

export default function Overview() {
  const [video, state, controls, ref] = useVideo(
    <video src={require('../assets/videos/M1.mp4')} muted playsInline />
  );
  const { x, y } = useWindowScroll();

  useEffect(() => {
    if (
      y > window.innerHeight / 2 &&
      !state.isPlaying &&
      state.time === 0 &&
      window.innerWidth > 1200
    ) {
      controls.play();
    } else if (y < 100) {
      controls.seek(0);
    }
  }, [y]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('html').classList.add('overview');
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Hero src={require('../assets/hero/mercury.svg')} />

      <div className="overview__vid">
        <Video>{video}</Video>
      </div>

      <img
        src={require('../assets/overview/mercury-is.svg')}
        className="overview__is"
      />

      <div className="points">
        <div className="point">
          <div className="point__title">Humane.</div>
          <div className="point__desc">
            Mercury rejects the Desktop Metaphor and App Ecosystems as
            fundamentally inhumane. Inspired by Jef Raskin’s principles, Mercury
            seeks to deliver a more humane take on human-computer interaction.
            <br />
            <br />
            <a href="https://medium.com/@jasonyuan/f4de45a04289">
              Read More on Medium
              <img
                src={require('../assets/overview/arrow-right.svg')}
                alt="Read more"
              />
            </a>
          </div>
        </div>
        <div className="point">
          <div className="point__title">Fluid.</div>
          <div className="point__desc">
            Instead of asking people to modify the way they think and do around
            the arbitrary sandboxes of Apps, Mercury responds fluidly to the
            needs and intentions of its user.
          </div>
        </div>
        <div className="point">
          <div className="point__title">Focused.</div>
          <div className="point__desc">
            The clutter we take for granted in today’s operating systems can be
            overwhelming, especially for folks sensitive to stimulation. Mercury
            is respectful of limited bandwidths and attention spans.
          </div>
        </div>
        <div className="point">
          <div className="point__title">Familiar.</div>
          <div className="point__desc">
            Mercury provides a glimpse into the systems of tomorrow using
            familiar interaction paradigms. It is designed for multi-touch
            tablets with keyboard support, a category of devices often
            overlooked as awkward hybrids straddling two worlds.
          </div>
        </div>
      </div>

      <Sep />

      <Chapter
        title="Architecture"
        desc="There are no Apps or Folders in Mercury. Instead, content and actions are fluidly assembled based on your intentions."
        to="/architecture"
      />

      <Section
        title="Modules"
        desc="Modules are the building blocks of Mercury. Modules can be freely defined using Natural Language Input (text and voice). Think of them as browsers capable of generating UI on demand."
      >
        <img
          className="section-image"
          srcSet={require('../assets/overview/1.png') + ' 2x'}
          alt="Modules"
        />
      </Section>

      <Section
        title="Flows"
        desc="Mercury is designed to help you enter and stay in flowstate. When one module isn’t enough, you can generate an adjacent Module by tapping the + button or by pressing Tab . Each new module will further inform Mercury as it aims to predict what you might need moving forward. This process can be repeated indefinitely. "
      >
        <img
          className="section-image"
          srcSet={require('../assets/overview/2.png') + ' 2x'}
          alt="Flows"
        />
      </Section>

      <Section
        title="Spaces"
        desc="Instead of hiding files inside folders and functions inside Apps, Mercury generates Spaces based on your intentions. Spaces can scale from a single module to multiple flows. It all depends on the specificity of your declared intent."
      >
        <img
          className="section-image"
          srcSet={require('../assets/overview/3.png') + ' 2x'}
          alt="Spaces"
        />
        <div
          style={{
            marginTop: '120px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button to="/architecture">Learn more about Architecture</Button>
        </div>
      </Section>

      <Sep />

      <Chapter
        title="Art Direction"
        desc="Mercury’s visual identity fuses the rational structures of Western Modernist design with the East Asian instinct to seek tranquility in chaos."
        to="/art-direction"
      />

      <Footer />
    </Fragment>
  );
}
