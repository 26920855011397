import React from 'react';
import { Link } from 'react-router-dom';

export default function Chapter(props) {
  const { title, desc, to } = props;
  return (
    <div className="chapter">
      <h2 className="chapter__title">{title}</h2>
      <p className="chapter__desc">{desc}</p>
      <Link to={to} className="chapter__learn">
        Learn more about {title}
      </Link>
    </div>
  );
}
