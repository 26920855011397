import React, { Fragment, useEffect, useState } from 'react';

import Navigation from '../components/Navigation';
import Nero from '../components/Nero';

export default function Acknowledgements() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('html').classList.remove('overview');
  }, []);
  return (
    <Fragment>
      <Navigation />
      <div className="credits">
        <div className="credits__title">Acknowledgements</div>
        <div className="credits__desc">
          <div style={{ fontSize: 24, paddingBottom: '1em' }}>
            Mercury would not have been possible without the generous feedback
            and encouragement from my wonderful peers, mentors, and friends.
          </div>
          <p>
            Thank you to Danny Trinh, Phill Ryu, Thomas Buffet, Mark Laughlin,
            Dandan Luo, Katie McIntyre, Jonathan Lee, Bob Burrough, Ed Kim, Marc Reisen, JaYoon Lee, Advait Kalakkad, Evan Rodgers,
            Gabby Widjaja, Cameron Burgess, Sharon Wei, Laurent Del Rey, Newar
            Choukeir, Victoria Wang, Aiden Fisher, Alex Schneidman, Devin Fan,
            Mac Wang, Eliza Chen, Sumaia Masoom, Theia Flynn, James Goggin,
            Sofia Soto, Evelyn Ma, Jennifer Joung, Vivian Wang, Lucas Ochoa,
            Kristina Selinski, SueSan Chen, and Gray Crawford.
          </p>
          <p>
            <b>Special thanks to:</b>
            <br />
            Marisa Lu for bringing brilliance and clarity to the darkest tunnels
            of this journey,
            <br />
            Jodi Leo for keeping me afloat and for making the world feel less
            alone,
            <br />
            Paul Soulellis for your guidance and empathy, for having faith in me
            when I had none,
            <br />
            Lindsey Weiss for your profound insights and for siblinghood,
            <br />
            Kris Sowersby for trusting me with bringing your characters to life,
            <br />
            Pam Daniels and Brandon Williams for introducing me to the world of
            design,
            <br />
            David Catlin and TBD for the gift of storytelling and the strength
            to speak up,
          </p>
          <p>
            And to Dennis Jin for being the epitome of an exponential
            collaborator. For helping shoulder the weight of Mercury, for your
            technical expertise, and for all the hours you poured into this.
            Truly incredible.
          </p>
          <p>Mercury is dedicated to the life and legacy of Jef Raskin.</p>
        </div>
      </div>
    </Fragment>
  );
}
