import React, { Fragment, useEffect, useState } from 'react';
import { useVideo, useWindowScroll, useEffectOnce } from 'react-use';

import Navigation from '../components/Navigation';
import Nero from '../components/Nero';
import Section from '../components/Section';
import Footer from '../components/Footer';

export default function Architecture() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('html').classList.remove('overview');
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Nero
        title="Art Direction"
        caption="Mercury’s visual identity fuses the rational structures of Western Modernist design with the East Asian instinct to seek tranquility in chaos."
      />

      <Section
        title="Fog"
        desc="Kiri, Japanese for “fog,” is the name of Mercury’s visual manifestation. Kiri takes a judicious approach to contrast—bringing clarity only where it is needed, while obfuscating extraneous noise under a soft mist. "
      >
        <img
          className="section-image"
          srcSet={require('../assets/artdir/1.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Section
        title="Choreography"
        desc="Motion is used throughout Mercury to maintain spatial consistency and to guide the user’s locus of attention from one module to the next. Mercury’s choreography was inspired by the Daoist Way of inexertion; the interface moves without resistance to human input before easing into the equilibrium of stillness."
      />

      <Section
        title="Typography"
        desc="Mercury emphasizes contrast in type size to reinforce information hierarchy and spatial consistency. Unlike the angular geometries of popular grotesque typefaces, Mercury’s chosen family* invites a sense of softness without sacrificing legibility."
      />

      <Section
        title="Where there’s light..."
        desc="Kiri retreats into the shadows when night falls. Modules are backlit with a sublime incandesence inspired by the ethereal glow of moonlight."
      >
        <img
          className="section-image"
          srcSet={require('../assets/artdir/3.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Footer />
    </Fragment>
  );
}
