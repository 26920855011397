import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Overview from './pages/Overview';
import Architecture from './pages/Architecture';
import ArtDirection from './pages/ArtDirection';
import Credits from './pages/Credits';
import NoMatch from './NoMatch';

import * as serviceWorker from './serviceWorker';
import './style/index-new.scss';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/" component={Overview} />
      <Route exact path="/architecture" component={Architecture} />
      <Route exact path="/art-direction" component={ArtDirection} />
      <Route exact path="/credits" component={Credits} />
      <Route component={NoMatch} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
