import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <div className="footer__content">
        <div className="footer__links">
          <div className="footer__heading">Links</div>
          <nav className="footer__nav">
            <Link to="/">Overview</Link>
            <Link to="/architecture">Architecture</Link>
            <Link to="/art-direction">Art Direction</Link>
            <Link to="/credits">Credits</Link>
          </nav>
        </div>
        <div className="footer__about">
          <div className="footer__heading">About</div>
          <p className="footer__p">
            Mercury OS is a speculative vision designed to question the
            paradigms governing human-computer interaction today. Questions and
            feedback are welcomed <a href="mailto:me@jasonyuan.design">here</a>.
            <br />
            <br />
            Copyright 2019 Jason Yuan Design. All rights reserved.
          </p>
        </div>
        <div className="footer__credits">
          <div className="footer__heading">Credits</div>
          <p className="footer__p">
            Design and Prototyping
            <br />
            Jason Yuan{' '}
            <a
              href="https://twitter.com/jasonyuandesign"
              style={{ opacity: 0.5 }}
            >
              @jasonyuandesign
            </a>
            <br />
            <br />
            Prototyping and Development
            <br />
            Dennis Jin{' '}
            <a href="https://twitter.com/seldom" style={{ opacity: 0.5 }}>
              @seldom
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
