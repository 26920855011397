import React from 'react';

export default function Hero(props) {
  const { src, caption } = props;
  return (
    <div className="hero">
      <img className="hero__img" src={src} alt="Mercury" />
      {caption ? <div className="hero__caption">{caption}</div> : null}
      <img
        className="hero__arrow"
        src={require('../assets/hero/arrow-down.svg')}
        alt="Scroll down"
      />
    </div>
  );
}
