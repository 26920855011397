import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navigation() {
  return (
    <header>
      <nav className="nav">
        <NavLink to="/" exact activeClassName="selected">
          Overview
        </NavLink>
        <NavLink to="/architecture" exact activeClassName="selected">
          Architecture
        </NavLink>
        <NavLink to="/art-direction" exact activeClassName="selected">
          Art Direction
        </NavLink>
        <NavLink to="/credits" exact activeClassName="selected">
          Credits
        </NavLink>
      </nav>
    </header>
  );
}
