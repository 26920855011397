import React from 'react';

export default function Video(props) {
  const { children } = props;
  return (
    <div className="vid">
      {children}
      <div className="vid__frame" />
    </div>
  );
}
