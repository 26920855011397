import React, { Fragment, useEffect, useState } from 'react';
import { useVideo, useWindowScroll, useEffectOnce } from 'react-use';

import Navigation from '../components/Navigation';
import Nero from '../components/Nero';
import Section from '../components/Section';
import Footer from '../components/Footer';
import Video from '../components/Video';
import Sep from '../components/FullSep';
import Button from '../components/Button';
import Chapter from '../components/Chapter';

export default function Architecture() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('html').classList.remove('overview');
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Nero
        title="Architecture"
        caption="No Apps or Folders. Mercury fluidly assembles content and actions based on your intentions. So you can focus on the destination, not the many ways to get there."
      />
      <Section
        title="Modules"
        desc="Modules are the building blocks of Mercury. They are defined using combinations of nouns (items), verbs (actions) and contextual modifiers. "
      >
        <img
          className="section-image arch-modules__img"
          srcSet={require('../assets/architecture/modules.png') + ' 2x'}
          alt=""
        />
        <div className="arch-modules__desc">
          Every Module can be defined and redefined using its <b>Locus</b> (the
          action bar). Locus combines the power of a Command-Line Interface with
          the convenience Natural Language Processing and the discoverability of
          GUI.
        </div>
      </Section>

      <Section
        subsection
        title="Leap beyond singular commands."
        desc={`Locus recognizes complete sentences as Actions, and can even perform tasks that involve multiple steps.

You can tap the Command key to toggle the display of available actions if you are unsure of where to start.`}
        align="right"
      >
        <img
          className="section-image"
          srcSet={require('../assets/architecture/2.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Section
        subsection
        align="left"
        title="Discover shortcuts you’ll never forget."
        desc={`In conventional operating systems, keyboard shortcuts are hard to remember and to find. Different Apps may require different shortcuts for the same function.
        
In Mercury, shortcuts are standardized through Locus. To see which ones are available, simply press and hold the Command key. Without letting go of Command, start typing to progressively filter the list of actions down to the one you want.`}
      >
        <video
          src={require('../assets/videos/CommandQuasi1.mp4')}
          autoPlay
          playsInline
          loop
          muted
          style={{
            display: 'block',
            width: 531,
            height: 500,
          }}
        />
      </Section>

      <Section
        subsection
        title="Responsive modules."
        desc="In addition to simple combinations of items and actions, you can also add contextual modifiers (like a conditional statement) to automate more nuanced tasks as you need them, without the need for any additional input."
      >
        <img
          className="section-image"
          srcSet={
            require('../assets/architecture/responsive-modules.png') + ' 2x'
          }
          alt=""
        />
      </Section>

      <Section
        title="Flows"
        desc={`In conventional App-driven operating systems, functions are segregated within different Apps. The process of moving from App to App generates friction that takes you out of flow and distracts you from your intentions.

Mercury is designed to help you enter and stay in flow. If you want to perform an action while leaving your current module untouched, you can generate a new module by tapping the + bubble adjacent to your current module, or by pressing the Tab key on your keyboard.`}
        // caption="An empty Module will be populated by actions and items Mercury thinks you may need given your context."
      >
        <video
          style={{ maxWidth: 900 }}
          src={require('../assets/videos/FLOWVid1.mp4')}
          autoPlay
          playsInline
          loop
          muted
        />
        <div className="section__caption">
          An empty Module will be populated by actions and items Mercury thinks
          you may need given your context.
        </div>
        <div className="section__desc">
          You can also use continuous gestural input to generate Modules without
          diverting your attention from the content you're engaged in. Simply
          highlight a chunk of text to get started.
        </div>
        <video
          style={{ maxWidth: 900 }}
          src={require('../assets/videos/flow2small.mp4')}
          autoPlay
          playsInline
          loop
          muted
        />
      </Section>

      <Section
        title="Spaces"
        desc="Instead of hiding files inside folders and functions inside Apps, Mercury generates Spaces based on your intentions. Spaces can scale from a single module to multiple flows. It all depends on the specificity of your declared intent. You can create a Space from scratch, use  a Blueprint, or ask Mercury to do it for you."
        caption="Declaring the “Review Inbox” intention generates a space with all your unread mail and incoming communications. You can navigate between different flows in a Space by swiping up and down, or by using the up and down arrow keys."
      >
        <Video>
          <video
            src={require('../assets/videos/MercurySpace.mp4')}
            autoPlay
            playsInline
            loop
            muted
          />
        </Video>
      </Section>

      <Section
        subsection
        title="Your space, your rules."
        desc="The pinch gesture reveals a birds-eye view of every parent module inside the space, as well as the rules and collaborators that define how the space is generated."
        caption="You can even ask them to generate modules automatically, or restrict their activity based on context. These Collaborators will not have access to any information outside of the modules directly affected by their input. The further sandboxing of Collaborators within Spaces protects your privacy while enabling intentional access to services you may rely on."
      >
        <Video>
          <video
            src={require('../assets/videos/MercuryGod.mp4')}
            autoPlay
            playsInline
            loop
            muted
          />
        </Video>

        <img
          style={{ margin: '120px auto', display: 'block' }}
          className="section-image"
          srcSet={require('../assets/architecture/collab.png') + ' 2x'}
          alt=""
        />

        <img
          style={{ margin: '0 auto', display: 'block' }}
          className="section-image"
          srcSet={require('../assets/architecture/6.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Section
        subsection
        title="Space for everyone."
        desc="Plan a trip to Vegas. Watch BLACKPINK’s comeback with your fandom. Share documents, images, and work collaboratively in real time by inviting others to join one of your Spaces. You could even give your secretary permission to add and remove items from your Inbox--without sharing a single password."
      >
        <img
          className="section-image"
          srcSet={require('../assets/architecture/7.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Section
        subsection
        title="All in good time."
        desc="A swipe up from the home screen reveals your chronological journey through time and, ahem, Space. Can’t remember what you worked on last Thursday? Just find it in the Timeline. Want to reflect on the amount of time you spent Tweeting about Game of Thrones this weekend? To the Timeline we go."
      >
        <img
          className="section-image"
          srcSet={require('../assets/architecture/8.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Section
        subsection
        title="Look smarter, not harder."
        desc="No need to scroll through hundreds of identical-looking files to find that one screenshot you took last month. Just describe what you’re looking for and Mercury will find it for you. "
      >
        <img
          className="section-image"
          srcSet={require('../assets/architecture/9.png') + ' 2x'}
          alt=""
        />
      </Section>

      <Footer />
    </Fragment>
  );
}
