import React from 'react';
import cx from 'classnames';

export default function Section(props) {
  const { title, desc, subsection, caption, children, align = '' } = props;
  return (
    <section
      className={cx({
        section: true,
        [align]: true,
      })}
    >
      <div>
        <div
          className={cx({
            section__title: true,
            'section__title--sub': subsection,
            'section__title--nochildren': !children,
          })}
        >
          {title}
        </div>
        <div className="section__desc">{desc}</div>
      </div>
      {children}
      {caption ? <div className="section__caption">{caption}</div> : null}
    </section>
  );
}
